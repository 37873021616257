import { mapHelper } from 'common/utils'

// 季度
const seasonNum = [
  {
    text: '1季度',
    value: 1
  },
  {
    text: '2季度',
    value: 2
  },
  {
    text: '3季度',
    value: 3
  },
  {
    text: '4季度',
    value: 4
  }
]

const { map: seasonNumMap, setOps: seasonNumOps } = mapHelper.setMap(seasonNum)

export { seasonNumMap, seasonNumOps }
